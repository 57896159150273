<template>
  <v-container fluid v-if="channel">
    <v-toolbar flat dense color="transparent">
      <v-breadcrumbs
        :items="[
          {
            exact: true,
            text: $t('epg.viewTitle'),
            to: { name: 'epg' },
          },
          {
            to: $route.path,
            text: channel.name,
          },
        ]"
      >
      </v-breadcrumbs>
      <v-spacer></v-spacer>
      <v-btn v-if="!noTv" icon @click="dialog = !dialog">
        <v-icon>mdi-cast</v-icon>
      </v-btn>
    </v-toolbar>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card flat>
        <v-card-text class="py-0">
          <remote-control-select></remote-control-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false">{{
            $t("modal.cancel")
          }}</v-btn>
          <v-btn
            text
            color="primary"
            :disabled="!selectedTv.id"
            @click="
              playEPGChannel({
                room_ip_id: selectedTv.id,
                start_channel_id: channel.id,
              }),
                (dialog = false)
            "
            >{{ $t("modal.ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-timeline
      align-top
      dense
      v-if="channel && channel.events && channel.events.length > 0"
    >
      <template v-for="(event, index) in channel.events">
        <template v-if="event.type !== 'placeholder'">
          <v-chip
            color="primary"
            class="white--text mb-3"
            label
            v-if="find(availableDates, { id: event.id })"
            style="width: 100%"
            :key="index"
            >{{ event.startDate }}</v-chip
          >
          <v-timeline-item
            :href="'#' + event.id"
            color="primary"
            :key="event.id"
            icon="mdi-television-box"
            fill-dot
          >
            <v-card outlined class="px-3">
              <v-card-title class="text-body-2"
                >{{ event.show_start_time }}
              </v-card-title>
              <v-progress-linear
                v-if="event.percent_passed > 0 && event.percent_passed < 100"
                :value="event.percent_passed"
              ></v-progress-linear>
              <v-divider></v-divider>
              <v-card-text class="pa-0">
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-list three-line class="pa-0">
                      <v-list-item
                        @click="
                          selectedEvent = event;
                          moreDialog = true;
                        "
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ event.event_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ event.event_description }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </template>
      </template>
    </v-timeline>
    <div class="text-center">
      <v-btn
        color="primary"
        :loading="channel.isFetchingEvents"
        text
        @click="setLimit(limit + 10)"
        >{{ $t("general.loadMore") }}</v-btn
      >
    </div>
    <v-dialog v-model="moreDialog">
      <v-card>
        <v-card-title>
          {{ selectedEvent.event_name }}
        </v-card-title>
        <v-card-text>
          <div class="text-overline">
            {{ selectedEvent.show_start_time }} -
            {{ selectedEvent.show_end_time }}
          </div>
          {{ selectedEvent.event_description }}</v-card-text
        >
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn @click="moreDialog = false" color="primary" text>{{
            $t("general.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import RemoteControlSelect from "@/components/remoteControl/RemoteControlSelect";
import find from "lodash/find";

export default {
  data() {
    return {
      dialog: false,
      moreDialog: false,
      selectedEvent: {},
    };
  },
  created() {
    this.initData();
  },
  components: {
    RemoteControlSelect,
  },
  computed: {
    channel() {
      return find(this.allChannels, (chn) => {
        return chn.id == this.$route.params.channelId;
      });
    },
    ...mapState({
      allChannels: ({ epg }) => epg.allChannels,
      limit: ({ epg }) => epg.limit,
      availableDates: ({ epg }) => epg.availableDates,
      selectedTv: ({ remoteControl }) => remoteControl.selectedTv,
      noTv: ({ remoteControl }) => remoteControl.noTv,
    }),
  },
  methods: {
    find,
    ...mapActions("epg", ["getEpgData", "playEPGChannel"]),
    ...mapMutations("epg", {
      setLimit: "SET_LIMIT",
      setTimezone: "SET_TIMEZONE",
    }),
    initData() {
      this.setLimit(10);
      this.setTimezone(this.$config.dateTimeZone);
      this.getEpgData({ item_id: this.$route.params.channelId });
    },
  },
  watch: {
    "$route.params.channelId"(val) {
      this.getEpgData({ item_id: val });
    },
    limit() {
      this.getEpgData({ item_id: this.$route.params.channelId });
    },
  },
};
</script>
